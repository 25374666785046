import { getFirebaseMessaging } from 'config/FirebaseConfig';
import { useEffect } from 'react';
import { api } from 'services/api';
import { useSelector } from 'store/redux/selector';

const isNotificationSupported = () =>
  'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;

export function useGetNotificationToken() {
  const user = useSelector(state => state.user);

  useEffect(() => {
    if (!user.id) {
      return;
    }

    if (!isNotificationSupported()) {
      return;
    }

    Notification.requestPermission()
      .then(async () => {
        const firebaseMessaging = getFirebaseMessaging();

        const token = await firebaseMessaging.getToken();

        const param = {
          push_notification_token: token,
          device: navigator.platform,
          type: 'admin',
        };

        api.post('/pushTokens', param).catch(error => console.error(error));
      })
      .catch(error => console.log(error));
  }, [user]);
}
